import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/DropdownMenu/DropdownMenu";

import { Icon } from "@/components/Icon";
import { formatPrice } from "../BDK_Calculator/lib/formatFunctions";
import HTML from "../HTML/HTML";

const RateInfoBubble = ({
  financedata,
  showNettoPreis = true,
  iconVariant = "TabStage",
}) => {
  const getIconVariant = (iconVariant) => {
    switch (iconVariant) {
      case "Product":
        return "text-alert hover:text-orange-light w-[12px] h-[12px] outline-none";
      case "ProductForm":
        return "text-blue-dark hover:text-blue-medium w-[12px] h-[12px] outline-none";
      case "TabStage":
        return "w-[12px] h-[12px] text-blue-dark hover:text-blue-light group-hover:text-white group-active:text-white outline-none";
      default:
        return "text-alert hover:text-orange-light";
    }
  };
  if (!financedata) return null;

  return (
    <PopUp iconVariant={getIconVariant(iconVariant)}>
      <FinanceInfoText
        financingdata={financedata}
        showNettoPreis={showNettoPreis}
      />
    </PopUp>
  );
};

const FinanceInfoText = ({ financingdata, showNettoPreis }) => {
  const { response = {}, request = {} } = financingdata;
  const {
    monthlyInstallmentTotalAmount = {},
    totalRepaymentAmount = {},
    balloon = {},
    netLoanAmount = {},
  } = response;
  const { effectiveInterestRate, term, deposit } = request;
  const string = `
  <table>
    <tr>
      <td>Anzahlung:</td>
      <td>${formatPrice(deposit) || "k.A"}</td>
    </tr>
    <tr>
      <td>Nettodarlehensbetrag:</td>
      <td>${formatPrice(netLoanAmount || 0)}</td>
    </tr>
    <tr>
      <td>Laufzeit:</td>
      <td>${term || "k.A"} Monate</td>
    </tr>
    <tr>
      <td>Effektiver Jahreszins:</td>
      <td>${effectiveInterestRate}%</td>
    </tr>
    <tr>
      <td>Schlussrate:</td>
      <td>${
        showNettoPreis
          ? formatPrice(balloon.netValue)
          : formatPrice(balloon.grossValue) || "k.A."
      }</td>
    </tr>
    
    <!-- Add the dividing line here -->
    <tr>
      <td colspan="2" class="py-4 text-gray-300 h-2"><hr /></td>
    </tr>
    
    <tr>
      <td>Gesamtbetrag:</td>
      <td>${
        showNettoPreis
          ? formatPrice(totalRepaymentAmount.netValue)
          : formatPrice(totalRepaymentAmount.grossValue) || "k.A."
      }</td>
    </tr>
    <tr>
      <td>Monatliche Rate ${
        showNettoPreis ? '<span class="text-xs">(zzgl. MwSt.)</span>' : ""
      }:</td>
      <td>${
        showNettoPreis
          ? formatPrice(monthlyInstallmentTotalAmount.netValue)
          : formatPrice(monthlyInstallmentTotalAmount.grossValue) || "k.A"
      }</td>
    </tr>
    <tr>
      <td colspan="2" class="text-[10px] italic leading-[14px] text-blue-dark text-wrap ">
        <br />
        Dies ist eine unverbindliche beispielhafte Finanzierungskalkulation der Bank Deutsches Kraftfahrzeuggewerbe GmbH, Fuhlsbütteler Str. 437, 22309 Hamburg.
        Hierbei handelt es sich gleichzeitig um das repräsentative Beispiel gem. §17 Abs. 4 PAngV. Bonität vorausgesetzt.
      </td>
    </tr>
  </table>
`;

  return (
    <div>
      <div className="leading-3">
        <HTML content={string || ""} />
      </div>
    </div>
  );
};

const PopUp = ({ children, iconVariant }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        asChild
        className="ml-3 mb-auto lg:block hidden outline-none"
      >
        <div>
          <Icon type="info" className={`${iconVariant}`} purposeLabel="Info" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" alignOffset={-10} sideOffset={5}>
        <div className="flex justify-evenly !z-[800] p-5 gap-4  bg-white text-blue-dark shadow-lg rounded-4 flex-wrap 2xl:max-w-[350px] lg:max-w-[286px] text-sm">
          {children}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default RateInfoBubble;
