import HoverCard from "@/components/HoverCard/HoverCard";
import { Icon } from "@/components/Icon";
import Image from "@/components/Image/Image";
import { Slot } from "utils/create-slots";

const PriceBlocks = ({ items, image = {} }) => {
  return (
    <div className="container mx-auto mt-8 mb-6 ">
      {image && (
        <div className="flex justify-center lg:mb-13 mb-6">
          <Image
            src={image[0].publicUrl}
            width={image[0].properties.cropDimensions.width}
            height={image[0].properties.cropDimensions.height}
            alt={"Alt"}
            role="presentation"
            className="mb-8"
          />
        </div>
      )}
      <div className=" grid grid-cols-1 md:grid-cols-3 gap-4 overflow-hidden rounded-2xl md:skew-x-[-30deg] md:rounded-tl-xl md:rounded-br-xl md:rounded-tr-[15px] md:rounded-bl-[15px] w-full">
        {items.map((item, index: number) => {
          const isFirst = index === 0;
          const isLast = index === items.length - 1;
          const optional = !!item.optional;

          return (
            <div
              key={index}
              className={`${
                optional ? "bg-gray-100" : "bg-gray-300"
              } py-9 px-2 flex items-center justify-center`}
            >
              <span
                key={index}
                className={` text-blue-dark text-center font-bold text-base lg:text-3xl flex items-center md:skew-x-[30deg]`}
              >
                {!isFirst && !isLast && (
                  <Icon type="plus" className="w-[20px] mr-5" />
                )}
                {item.title}
                {item.text && (
                  <HoverCard>
                    <Slot name="content">
                      <p>{item.text}</p>
                    </Slot>
                  </HoverCard>
                )}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PriceBlocks;

export const Typo3PriceBlocks = ({ content }) => {
  const { items = [], image = [] } = content;
  return <PriceBlocks items={items} image={image} />;
};
