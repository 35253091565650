import Badge from "@/components/Badge/Badge";
import { Icon } from "@/components/Icon";
import Separator from "@/components/Separator/Separator";
import { useElementInViewport } from "hooks/useElementInViewport";
import {
  createContext,
  FC,
  Fragment,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { createSlots, Slot } from "utils/create-slots";
import { WishlistContext } from "../Wishlist/WishlistProvider";
import Media from "./Media";
import * as AspectRatioPrimitive from "@radix-ui/react-aspect-ratio";
import Car from "@/data/car/car";
import s from "./Product.module.css";
import Slider from "@/components/Slider/Slider";
import Link from "@/components/Link/Link";
import { Button } from "@/components/Button/Button";
import Headlines from "../Headlines/Headlines";
import * as gtag from "@/lib/gtag";
import { formatPrice } from "../BDK_Calculator/lib/formatFunctions";
import RateInfoBubble from "../RateInfoBubble/RateInfoBubble";

export const ProductContext = createContext({
  hoverState: false,
  isInViewport: false,
});

const Product: FC<{
  item: any;
  hasVideo?: boolean;
  mediaSizes?: { [key: string]: string };
  priority?: boolean;
  className?: string;
  isWishlist?: boolean;
  isSharedWishlist?: boolean;
}> = ({
  item,
  mediaSizes,
  priority = false,
  className = "",
  isWishlist = false,
  hasVideo = false,
  isSharedWishlist = false,
}) => {
  const tile = useRef<HTMLDivElement>(null);
  const [hoverState, setHoverState] = useState(false);
  const isInViewport = useElementInViewport(
    {
      root: null,
      rootMargin: "-180px 0px 0px 0px",
      threshold: 0.9,
    },
    tile,
    1500
  ) as any;

  const car = useMemo(() => Car.from(item), [item]);

  if (!car) return null;
  const gtmTracking = () => {
    gtag.event({
      event: "itemClick",
      itemClick: "productTile",
      itemName: car.title,
      itemType: "button",
      itemAction: "click",
      itemPosition: "detail page",
      vehicleType: car.vehicleType,
      vehicleModel: car.modell,
      vehicleEquipmentLine: car.equipmentline,
      vehicleOfferNumber: car.uid,
    });
  };
  const handleClick = () => {
    gtmTracking();
  };

  let leasingdata;
  let financedata;

  if (typeof item.leasingdata !== "undefined") {
    leasingdata =
      typeof item.leasingdata === "object"
        ? item.leasingdata
        : JSON.parse(item.leasingdata);
  } else {
    leasingdata = {};
  }
  if (typeof item.financingdata !== "undefined") {
    financedata =
      typeof item.financingdata === "object"
        ? item.financingdata
        : JSON.parse(item.financingdata);
  } else {
    leasingdata = {};
  }

  const getMonthlyRateLeasing = (obj, showNetto) => {
    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
      return " Error in API - Please Check";
    } else {
      if (showNetto) {
        return `${formatPrice(
          leasingdata.response.monthlyInstallment.netValue
        )}`;
      } else {
        return `${formatPrice(
          leasingdata.response.monthlyInstallment.grossValue
        )}`;
      }
    }
  };
  const getMonthlyRateFinancing = (obj, showNetto) => {
    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
      return " Error in API - Please Check";
    } else {
      if (showNetto) {
        return `${formatPrice(
          financedata.response.monthlyInstallmentTotalAmount.netValue
        )}`;
      } else {
        return `${formatPrice(
          financedata.response.monthlyInstallmentTotalAmount.grossValue
        )}`;
      }
    }
  };

  return (
    <ProductContext.Provider value={{ hoverState, isInViewport }}>
      <div
        ref={tile}
        onClick={handleClick}
        onMouseEnter={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
        className={`shadow-md w-full min-h-[100px] bg-white ${s.Product} h-full flex flex-col ${className} `}
      >
        <AspectRatioPrimitive.Root ratio={4 / 3}>
          <>
            <Media
              alt={`${car?.title} ${car?.farbe ? `in ${car?.farbe}` : ""}`}
              content={car.assets}
              sizesObj={mediaSizes}
              priority={priority}
              hasVideo={hasVideo}
              index={car.uid}
            />
            <div className="absolute top-4 left-5 min-h-[100px]">
              {car.rabatt > 0 && (
                <Badge color="discount" size="small">
                  -{car.rabatt}%
                  <span className={`${s.BadgeText} ml-3`}>Rabatt</span>*
                </Badge>
              )}
            </div>
            <div className="absolute bottom-3 right-5 ">
              <Badge color="warning" size="small">
                <Icon type="photo" className="inline w-[11px] mr-3" />
                100<span className={s.BadgeText}>&nbsp;Premium Bilder</span>
              </Badge>
            </div>
            {!isSharedWishlist && (
              <WishlistButton car={car} isWishlist={isWishlist} />
            )}
          </>
        </AspectRatioPrimitive.Root>
        <div className=" px-5 pb-3 pt-4 h-full">
          <Content isleasable={car.isleasable} isfinancable={car.isfinanceable}>
            <Slot name="title">{car?.title} </Slot>
            <Slot name="subtitle">{car?.title_sub}</Slot>
            {car?.formattedPrice && (
              <Slot name="price">
                {car.formattedPrice}{" "}
                {car.showNettoPreis && (
                  <span className={`text-xs ml-2 ${s.MwstSpan} `}>
                    zzgl. MwSt.
                  </span>
                )}
              </Slot>
            )}

            {car && (
              <Slot name="monthlyPrice">
                <span className="text-xs mr-3">ab</span>

                {car.isfinanceable &&
                  getMonthlyRateFinancing(financedata, car.showNettoPreis)}

                {car.isleasable &&
                  !car.isfinanceable &&
                  getMonthlyRateLeasing(leasingdata, car.showNettoPreis)}
                {car.showNettoPreis && (
                  <span className={`text-xs ml-3 ${s.MwstSpan} `}>
                    zzgl. MwSt.
                  </span>
                )}
              </Slot>
            )}
            {financedata && (
              <Slot name="rateInfoBubble">
                <RateInfoBubble
                  financedata={financedata}
                  showNettoPreis={car.showNettoPreis}
                  iconVariant={"Product"}
                />
              </Slot>
            )}

            {car?.rabatt && <Slot name="discount">-{car.rabatt}% Rabatt</Slot>}
            <Slot name="features">
              <li className="truncate ">{car?.ezTeaser}</li>
              <li className="text-gray-300">&#8226;</li>
              <li>{car?.formattedKilometer}</li>
              <li className="text-gray-300">&#8226;</li>
              <li className="truncate max-w-[40px] 2xl:max-w-[120px]">
                {car?.kraftstoffart}
              </li>

              <li className="text-gray-300 ">&#8226;</li>
              <li>{car?.getriebeart}</li>
              <li className={`text-gray-300 mx-0 lg:flex  ${s.ContainerWidth}`}>
                &#8226;
              </li>
              <li className={`lg:flex  ${s.ContainerWidth} `}>{car.ps} PS</li>
              <li className={`text-gray-300 lg:flex  ${s.ContainerWidth} `}>
                &#8226;
              </li>
              <li className={`lg:flex  ${s.ContainerWidth} `}>
                {car.originalfarbe || car.farbe}
              </li>

              {car.tueren && (
                <Fragment>
                  <li className={`text-gray-300 lg:flex  ${s.ContainerWidth}`}>
                    &#8226;
                  </li>
                  <li className={`lg:flex  ${s.ContainerWidth} `}>
                    Türen: {car?.tueren}
                  </li>
                  <li className={`text-gray-300 lg:flex  ${s.ContainerWidth}`}>
                    &#8226;
                  </li>
                </Fragment>
              )}
              {car.sitze && (
                <Fragment>
                  <li className={`lg:flex  ${s.ContainerWidth} `}>
                    Sitze: {car?.sitze}
                  </li>
                </Fragment>
              )}
              {car.ccm > 0 && (
                <Fragment>
                  <li className={`text-gray-300 lg:flex  ${s.ContainerWidth}`}>
                    &#8226;
                  </li>
                  <li className={`lg:flex  ${s.ContainerWidth} `}>
                    Hubraum: {car.ccm.toLocaleString("de-DE")} cm³
                  </li>
                </Fragment>
              )}
            </Slot>
            <Slot name="finePrint">{car?.consumptionAndCo2Emission}</Slot>
          </Content>
        </div>
      </div>
    </ProductContext.Provider>
  );
};

const Content = ({ isleasable, isfinancable, children }) => {
  const slots = [
    "title",
    "subtitle",
    "price",
    "monthlyPrice",
    "rateInfoBubble",
    "features",
    "finePrint",
  ];
  const [
    title,
    subtitle,
    price,
    monthlyPrice,
    rateInfoBubble,
    features,
    finePrint,
  ] = createSlots(slots, children);

  return (
    <div className="h-full flex flex-col">
      <div className="my-3">
        <div className="flex w-full justify-between">
          <p className="text-sm truncate max-w-[200px] font-bold mb-3">
            {title}
          </p>
          <div
            className={`flex items-center shrink-0 -mt-4 ${s.BadgeContainer}`}
          >
            <Badge color="success" size="small">
              auf Lager
            </Badge>
          </div>
        </div>
        <p className="text-[12px] font-normal text-blue-dark truncate xl:max-w-[290px] lg:max-w-[200px] mb-4">
          {subtitle}
        </p>
      </div>
      <div className={`flex justify-between text-blue-dark ${s.ProductPrices}`}>
        {price && (
          <div
            className={`font-bold flex justify-between items-center mb-3 lg:mr-[16px] ${s.RowWhenSmall}`}
          >
            <span className="text-[12px] mr-2">Sofortkaufpreis</span>
            <span className={`text-sm text-alert   ${s.FlexWhenSmall}`}>
              {price}
            </span>
          </div>
        )}
        {monthlyPrice && isfinancable && (
          <div
            className={`font-bold flex justify-between items-center ${s.RowWhenSmall}`}
          >
            <span className="text-[12px] mr-2">mtl. finanzieren**</span>
            <span
              className={`text-sm text-alert whitespace-nowrap flex flex-row items-baseline ${s.FlexWhenSmall}`}
            >
              {monthlyPrice}
              {rateInfoBubble}
            </span>
          </div>
        )}
        {monthlyPrice && isleasable && !isfinancable && (
          <div
            className={`font-bold flex justify-between items-center ${s.RowWhenSmall}`}
          >
            <span className="text-[12px] mr-2">mtl.leasen**</span>
            <span className={`text-sm text-alert ${s.FlexWhenSmall}`}>
              {monthlyPrice}
            </span>
          </div>
        )}
        {!isleasable && !isfinancable && (
          <div
            className={`font-bold flex justify-between items-center ${s.RowWhenSmall} opacity-0 select-none`}
          >
            <span className="text-[12px] mr-2">mtl.leasen**</span>
            <span className={`text-sm text-alert ${s.FlexWhenSmall}`}>-</span>
          </div>
        )}
      </div>

      <div
        className={`${s.BadgeContainerViewSmall} items-center shrink-0 mt-4`}
      >
        <Badge color="success" size="small">
          auf Lager
        </Badge>
      </div>
      <Separator className="!bg-gray-300 mt-4" />
      <ul className="text-[12px] flex flex-row gap-x-4 gap-y-4 my-4 lg:my-5  flex-wrap ">
        {features}
      </ul>
      <Separator className="!bg-gray-300 hidden lg:block lg:mt-auto" />
      <p className="text-xs my-4 hidden lg:block">{finePrint}</p>
    </div>
  );
};

export default Object.assign(Product, {
  Media,
  Content,
});

export const Typo3ProductSlider = ({ content }) => {
  const { data, header, subheader } = content;
  const { cars = [], settings = {} } = data;

  return (
    <div className="container mx-auto">
      <Headlines {...content} />
      <Slider
        aria-label="Fahrzeug Slider"
        className="flex !visible pb-0"
        options={{
          perPage: 3,
          perMove: 1,
          autoHeight: true,
          pagination: false,
          breakpoints: {
            640: {
              perPage: 1,
            },
          },
        }}
      >
        {cars.map((car) => {
          return (
            <Slider.Slide key={car.internalid} className="px-5 py-8">
              <Link href={car.link.detail}>
                <Product
                  hasVideo={false}
                  priority={false}
                  item={car}
                  mediaSizes={{
                    "(max-width: 1024px)": "25vw",
                    "(max-width: 1280px)": "33vw",
                    "": "25vw",
                  }}
                />
              </Link>
            </Slider.Slide>
          );
        })}
      </Slider>
      {((settings?.linkText && settings?.link) ||
        (settings?.secondLink && settings.secondlinkText)) && (
        <div className="flex w-full justify-center items-center mt-8 lg:mb-8 lg:mt-0 lg:gap-9 gap-6 flex-col lg:flex-row">
          {settings?.linkText && settings?.link && (
            <Link href={settings.link} passHref legacyBehavior>
              <Button
                as="a"
                className="cursor-pointer lg:w-1/3 xl:w-1/4 w-full"
              >
                {settings.linkText}
              </Button>
            </Link>
          )}
          {settings?.secondLink && settings.secondlinkText && (
            <Link href={settings.secondLink} passHref legacyBehavior>
              <Button
                as="a"
                className="cursor-pointer lg:w-1/3 xl:w-1/4 w-full"
                variant="hollow"
              >
                {settings.secondlinkText}
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

const WishlistButton = ({ car, isWishlist }) => {
  const { isOnWishlist, addToWishlist, removeFromWishlist } =
    useContext(WishlistContext);

  const icon = isWishlist ? "close" : "star-filled";

  return (
    <button
      className="absolute top-5 right-5 py-3 px-3 rounded-4 bg-[#ffffff66] text-blue-light hover:text-blue-dark"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isOnWishlist(car.uid)) {
          removeFromWishlist(car.uid);
          gtag.event({
            event: "itemClick",
            itemClick: "delete",
            itemType: "button",
            itemAction: "click",
            itemPosition: "watchlist",
          });
        } else {
          addToWishlist(car.uid);
          gtag.event({
            event: "itemClick",
            itemClick: "AddToWatchlist",
            itemType: "button",
            itemAction: "click",
            itemPosition: "watchlist",
            vehicleModel: `${car.model}`,
            vehilceEquipmentline: `${car.equipmentline}`,
            vehicleOfferNumber: `${car.uid}`,
          });

          gtag.event({
            event: "add_to_cart",
            ecommerce: {
              value: car.preis || "",
              currency: "EUR",
              items: [
                {
                  item_id: car.internalid || "",
                  item_name: `${car.marke} ${car.subtitle}` || "",
                  item_brand: car.marke || "",
                  item_category: car.vehicleType || "",
                  item_category2: car.usagecategory || "",
                  item_category3: car.getriebeart || "",
                  item_category4: car.kraftstoffart || "",
                  item_variant: car.farbe || "",
                  price: car.preis || "",
                  quantity: 1,
                },
              ],
            },
          });
        }
      }}
    >
      <Icon
        purposeLabel={
          isOnWishlist(car.uid)
            ? "Von Merkliste entfernen"
            : "Zu Merkliste hinzufügen"
        }
        type={isOnWishlist(car.uid) ? icon : "star"}
        className={` transition-all w-[24px]`}
      />
    </button>
  );
};
