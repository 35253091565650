import { FC, useRef, useState } from "react";
import * as gtag from "@/lib/gtag";
import { useIsomorphicLayoutEffect } from "hooks/useIsomorphicLayoutEffect";

interface CDNVideoProps {
  src: string;
  playing: boolean;
  preview?: boolean;
  tracking?: boolean;
  setEnded?: any;
  isMuted?: boolean;
}
let videoPlayPromise;
const CDNVideo: FC<CDNVideoProps & React.HTMLProps<HTMLVideoElement>> = ({
  src,
  playing,
  preview = false,
  tracking = false,
  setEnded,
  isMuted = true,
  ...props
}) => {
  const video = useRef<HTMLVideoElement>(null);
  const [width, setWidth] = useState<1280 | 480>(480);

  useIsomorphicLayoutEffect(() => {
    if (window.innerWidth > 992) {
      setWidth(1280);
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (video.current === null) return;
    video.current.muted = true;
    videoPlayPromise = video.current.play();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  const getSrc = (src, type, format, width) => {
    const query = `${process.env.NEXT_PUBLIC_CLOUDFRONT_VIDEO_URL}/${src}/${type}/${src}_${
      preview ? resolutions.preview : resolutions[width]
    }.${format}#t=1`;
    return query;
  };

  const resolutions = { 1280: "1280x720", 480: "480x270", preview: "preview" };
  const videoTypes = [
    {
      type: "MP4",
      format: "mp4",
    },
    {
      type: "WEBM",
      format: "webm",
    },
  ];
  const trackVideoPlaytime = (videoElement, trackingFunction) => {
    let intervalId;

    const startTime = Date.now();
    const duration = 134000;

    const updatePlaytime = (event) => {
      const elapsedTime = Date.now() - startTime;
      const playtimeInSeconds = Math.round(elapsedTime / 1000);
      const percentPlayed = Math.round((elapsedTime / duration) * 100);
      if (event === "pause" || event === "ended") {
        trackingFunction(playtimeInSeconds, percentPlayed);
      }
    };

    videoElement.addEventListener("play", () => {
      intervalId = setInterval(updatePlaytime, 1000);
    });

    videoElement.addEventListener("pause", () => {
      clearInterval(intervalId);

      updatePlaytime("pause");
    });

    videoElement.addEventListener("ended", () => {
      clearInterval(intervalId);
      updatePlaytime("ended");
    });
  };
  useIsomorphicLayoutEffect(() => {
    if (video.current && tracking) {
      trackVideoPlaytime(video.current, (playtimeInSeconds, percentPlayed) => {
        gtag.event({
          event: "itemClick",
          itemClick: "videoPlayed",
          videoPercent: `${percentPlayed <= 100 ? percentPlayed : 100}%`,
          videoDuration: 134,
          videoPlaytime: playtimeInSeconds,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVideoEnded = () => {
    if (!setEnded) return;
    setEnded(true);
  };

  return (
    <video
      width={`${width}`}
      height="auto"
      controls
      ref={video}
      muted={isMuted ? true : false}
      {...props}
      playsInline
      onEnded={handleVideoEnded}
    >
      {videoTypes.map((item, index) => {
        return (
          <source
            key={index}
            src={getSrc(src, item.type, item.format, width)}
            type={`video/${item.format}`}
          />
        );
      })}
    </video>
  );
};

export default CDNVideo;
