import { getTextColorClass, textColorVariant } from "@/lib/getTextColorClass";
import { cva } from "class-variance-authority";
import { FC, PropsWithChildren } from "react";

type HeaderType = "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface HeaderProps {
  type: HeaderType;
  className: string;
}

const headlineStyles = cva("font-bold", {
  variants: {
    textColor: textColorVariant,
    headerLayout: {
      0: "lg:text-5xl text-3xl md:text-4xl ",
      1: "text-5xl md:text-6xl lg:text-7xl",
      2: "text-4xl md:text-5xl lg:text-6xl",
      3: "text-3xl md:text-4xl lg:text-5xl",
      4: "text-2xl md:text-3xl lg:text-4xl",
      5: "text-xl md:text-2xl lg:text-3xl",
      6: "text-xl",
      100: "hidden",
    },
    headerPosition: {
      0: "text-center",
      1: "text-start",
      2: "text-end",
    },
    shadow: {
      true: "text-shadow",
      false: "",
    },
  },
  defaultVariants: {
    textColor: "blue",
    headerLayout: 3,
    headerPosition: 0,
    shadow: false,
  },
});

const subHeadlineStyles = cva("mb-8 lg:mb-9", {
  variants: {
    textColor: textColorVariant,
    headerPosition: {
      0: "text-center",
      1: "text-start",
      2: "text-end",
    },
    headerLayout: {
      0: "text-xl md:text-2xl lg:text-3xl",
      1: "text-3xl md:text-4xl lg:text-5xl",
      2: "text-2xl md:text-3xl lg:text-4xl",
      3: "text-xl md:text-2xl lg:text-3xl",
      4: "text-lg md:text-xl lg:text-2xl",
      5: "text-base md:text-lg lg:text-xl",
      6: "text-base",
      100: "hidden",
    },
    shadow: {
      true: "text-shadow",
      false: "",
    },
  },
  defaultVariants: {
    textColor: "blue",
    headerPosition: 0,
    headerLayout: 3,
    shadow: false,
  },
});

const Header: FC<PropsWithChildren<HeaderProps>> = ({
  children,
  type = "h3",
  className = "",
}) => {
  const Component = type;
  return <Component className={className}>{children}</Component>;
};

const Subheader = ({ children, className = "" }) => (
  <p className={`${className}`}>{children}</p>
);

const Headlines: FC<{
  subheader: string;
  header: string;
  textColor?: "white" | "blue";
  headerLayout?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 100;
  headerPosition?: 0 | 1 | 2;
  className?: string;
  as?: HeaderType;
  shadow?: boolean;
}> = ({
  subheader,
  header,
  textColor = "blue",
  headerLayout = 3,
  className = "",
  headerPosition = 0,
  shadow = false,
  as,
}) => {
  return (
    <>
      {header && (
        <Header
          className={`${className} ${headlineStyles({
            textColor,
            headerLayout,
            headerPosition,
            shadow,
          })}`}
          type={
            as ||
            `h${
              headerLayout === 100
                ? 2
                : headerLayout && headerLayout > 0
                ? headerLayout
                : 3
            }`
          }
        >
          {header}
        </Header>
      )}
      {subheader && (
        <Subheader
          className={`${subHeadlineStyles({
            textColor,
            headerLayout,
            headerPosition,
            shadow,
          })}`}
        >
          {subheader}
        </Subheader>
      )}
    </>
  );
};

export default Headlines;

export const Typo3Headlines = ({ content }) => {
  return (
    <div className=" w-full container mx-auto my-8 ">
      <Headlines {...content} />
    </div>
  );
};
