import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { forwardRef } from "react";

export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuArrow = DropdownMenuPrimitive.Arrow;

export const DropdownMenuContent = forwardRef<HTMLDivElement, any>(
  ({ children, ...props }, forwardedRef, arrowColorClass = "fill-alert") => {
    return (
      <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content
          side="bottom"
          avoidCollisions={false}
          {...props}
          ref={forwardedRef}
          className="!drop-shadow-2xl !z-50"
        >
          {children}
          <DropdownMenuPrimitive.Arrow className="fill-white shadow-lg" />
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Portal>
    );
  }
);
DropdownMenuContent.displayName = "DropdownMenuContent";

export default Object.assign(DropdownMenu);
